import { getRoundNumber } from '@rc-component/color-picker/lib/util';
import React, { useEffect, useState } from 'react';
import { generateColor } from '../util';
import ColorSteppers from './ColorSteppers';
const ColorHsbInput = ({ prefixCls, value, onChange }) => {
    const colorHsbInputPrefixCls = `${prefixCls}-hsb-input`;
    const [hsbValue, setHsbValue] = useState(generateColor(value || '#000'));
    // Update step value
    useEffect(() => {
        if (value) {
            setHsbValue(value);
        }
    }, [value]);
    const handleHsbChange = (step, type) => {
        const hsb = hsbValue.toHsb();
        hsb[type] = type === 'h' ? step : (step || 0) / 100;
        const genColor = generateColor(hsb);
        if (!value) {
            setHsbValue(genColor);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(genColor);
    };
    return (React.createElement("div", { className: colorHsbInputPrefixCls },
        React.createElement(ColorSteppers, { max: 360, min: 0, value: Number(hsbValue.toHsb().h), prefixCls: prefixCls, className: colorHsbInputPrefixCls, formatter: (step) => getRoundNumber(step || 0).toString(), onChange: (step) => handleHsbChange(Number(step), 'h') }),
        React.createElement(ColorSteppers, { max: 100, min: 0, value: Number(hsbValue.toHsb().s) * 100, prefixCls: prefixCls, className: colorHsbInputPrefixCls, formatter: (step) => `${getRoundNumber(step || 0)}%`, onChange: (step) => handleHsbChange(Number(step), 's') }),
        React.createElement(ColorSteppers, { max: 100, min: 0, value: Number(hsbValue.toHsb().b) * 100, prefixCls: prefixCls, className: colorHsbInputPrefixCls, formatter: (step) => `${getRoundNumber(step || 0)}%`, onChange: (step) => handleHsbChange(Number(step), 'b') })));
};
export default ColorHsbInput;
