/**
 * TODO: 4.0
 *
 * - Remove `dataSource`
 * - `size` not work with customizeInput
 * - CustomizeInput not feedback `ENTER` key since accessibility enhancement
 */
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import genPurePanel from '../_util/PurePanel';
import { isValidElement } from '../_util/reactNode';
import warning from '../_util/warning';
import { ConfigContext } from '../config-provider';
import Select from '../select';
const { Option } = Select;
function isSelectOptionOrSelectOptGroup(child) {
    return child && child.type && (child.type.isSelectOption || child.type.isSelectOptGroup);
}
const AutoComplete = (props, ref) => {
    const { prefixCls: customizePrefixCls, className, popupClassName, dropdownClassName, children, dataSource, } = props;
    const childNodes = toArray(children);
    // ============================= Input =============================
    let customizeInput;
    if (childNodes.length === 1 &&
        isValidElement(childNodes[0]) &&
        !isSelectOptionOrSelectOptGroup(childNodes[0])) {
        [customizeInput] = childNodes;
    }
    const getInputElement = customizeInput ? () => customizeInput : undefined;
    // ============================ Options ============================
    let optionChildren;
    // [Legacy] convert `children` or `dataSource` into option children
    if (childNodes.length && isSelectOptionOrSelectOptGroup(childNodes[0])) {
        optionChildren = children;
    }
    else {
        optionChildren = dataSource
            ? dataSource.map((item) => {
                if (isValidElement(item)) {
                    return item;
                }
                switch (typeof item) {
                    case 'string':
                        return (React.createElement(Option, { key: item, value: item }, item));
                    case 'object': {
                        const { value: optionValue } = item;
                        return (React.createElement(Option, { key: optionValue, value: optionValue }, item.text));
                    }
                    default:
                        warning(false, 'AutoComplete', '`dataSource` is only supports type `string[] | Object[]`.');
                        return undefined;
                }
            })
            : [];
    }
    if (process.env.NODE_ENV !== 'production') {
        warning(!('dataSource' in props), 'AutoComplete', '`dataSource` is deprecated, please use `options` instead.');
        warning(!customizeInput || !('size' in props), 'AutoComplete', 'You need to control style self instead of setting `size` when using customize input.');
        warning(!dropdownClassName, 'AutoComplete', '`dropdownClassName` is deprecated, please use `popupClassName` instead.');
    }
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    return (React.createElement(Select, Object.assign({ ref: ref, showArrow: false }, omit(props, ['dataSource', 'dropdownClassName']), { prefixCls: prefixCls, popupClassName: popupClassName || dropdownClassName, className: classNames(`${prefixCls}-auto-complete`, className), mode: Select.SECRET_COMBOBOX_MODE_DO_NOT_USE }, {
        // Internal api
        getInputElement,
    }), optionChildren));
};
const RefAutoComplete = React.forwardRef(AutoComplete);
// We don't care debug panel
/* istanbul ignore next */
const PurePanel = genPurePanel(RefAutoComplete);
RefAutoComplete.Option = Option;
RefAutoComplete._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
if (process.env.NODE_ENV !== 'production') {
    AutoComplete.displayName = 'AutoComplete';
}
export default RefAutoComplete;
