import DownOutlined from '@ant-design/icons/DownOutlined';
import classNames from 'classnames';
import { INTERNAL_COL_DEFINE } from 'rc-table';
import { arrAdd, arrDel } from 'rc-tree/lib/util';
import { conductCheck } from 'rc-tree/lib/utils/conductUtil';
import { convertDataToEntities } from 'rc-tree/lib/utils/treeUtil';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import warning from '../../_util/warning';
import Checkbox from '../../checkbox';
import Dropdown from '../../dropdown';
import Radio from '../../radio';
// TODO: warning if use ajax!!!
export const SELECTION_COLUMN = {};
export const SELECTION_ALL = 'SELECT_ALL';
export const SELECTION_INVERT = 'SELECT_INVERT';
export const SELECTION_NONE = 'SELECT_NONE';
const EMPTY_LIST = [];
const flattenData = (childrenColumnName, data) => {
    let list = [];
    (data || []).forEach((record) => {
        list.push(record);
        if (record && typeof record === 'object' && childrenColumnName in record) {
            list = [...list, ...flattenData(childrenColumnName, record[childrenColumnName])];
        }
    });
    return list;
};
const useSelection = (config, rowSelection) => {
    const { preserveSelectedRowKeys, selectedRowKeys, defaultSelectedRowKeys, getCheckboxProps, onChange: onSelectionChange, onSelect, onSelectAll, onSelectInvert, onSelectNone, onSelectMultiple, columnWidth: selectionColWidth, type: selectionType, selections, fixed, renderCell: customizeRenderCell, hideSelectAll, checkStrictly = true, } = rowSelection || {};
    const { prefixCls, data, pageData, getRecordByKey, getRowKey, expandType, childrenColumnName, locale: tableLocale, getPopupContainer, } = config;
    // ========================= Keys =========================
    const [mergedSelectedKeys, setMergedSelectedKeys] = useMergedState(selectedRowKeys || defaultSelectedRowKeys || EMPTY_LIST, {
        value: selectedRowKeys,
    });
    // ======================== Caches ========================
    const preserveRecordsRef = React.useRef(new Map());
    const updatePreserveRecordsCache = useCallback((keys) => {
        if (preserveSelectedRowKeys) {
            const newCache = new Map();
            // Keep key if mark as preserveSelectedRowKeys
            keys.forEach((key) => {
                let record = getRecordByKey(key);
                if (!record && preserveRecordsRef.current.has(key)) {
                    record = preserveRecordsRef.current.get(key);
                }
                newCache.set(key, record);
            });
            // Refresh to new cache
            preserveRecordsRef.current = newCache;
        }
    }, [getRecordByKey, preserveSelectedRowKeys]);
    // Update cache with selectedKeys
    React.useEffect(() => {
        updatePreserveRecordsCache(mergedSelectedKeys);
    }, [mergedSelectedKeys]);
    const { keyEntities } = useMemo(() => checkStrictly
        ? { keyEntities: null }
        : convertDataToEntities(data, {
            externalGetKey: getRowKey,
            childrenPropName: childrenColumnName,
        }), [data, getRowKey, checkStrictly, childrenColumnName]);
    // Get flatten data
    const flattedData = useMemo(() => flattenData(childrenColumnName, pageData), [childrenColumnName, pageData]);
    // Get all checkbox props
    const checkboxPropsMap = useMemo(() => {
        const map = new Map();
        flattedData.forEach((record, index) => {
            const key = getRowKey(record, index);
            const checkboxProps = (getCheckboxProps ? getCheckboxProps(record) : null) || {};
            map.set(key, checkboxProps);
            warning(!('checked' in checkboxProps || 'defaultChecked' in checkboxProps), 'Table', 'Do not set `checked` or `defaultChecked` in `getCheckboxProps`. Please use `selectedRowKeys` instead.');
        });
        return map;
    }, [flattedData, getRowKey, getCheckboxProps]);
    const isCheckboxDisabled = useCallback((r) => { var _a; return !!((_a = checkboxPropsMap.get(getRowKey(r))) === null || _a === void 0 ? void 0 : _a.disabled); }, [checkboxPropsMap, getRowKey]);
    const [derivedSelectedKeys, derivedHalfSelectedKeys] = useMemo(() => {
        if (checkStrictly) {
            return [mergedSelectedKeys || [], []];
        }
        const { checkedKeys, halfCheckedKeys } = conductCheck(mergedSelectedKeys, true, keyEntities, isCheckboxDisabled);
        return [checkedKeys || [], halfCheckedKeys];
    }, [mergedSelectedKeys, checkStrictly, keyEntities, isCheckboxDisabled]);
    const derivedSelectedKeySet = useMemo(() => {
        const keys = selectionType === 'radio' ? derivedSelectedKeys.slice(0, 1) : derivedSelectedKeys;
        return new Set(keys);
    }, [derivedSelectedKeys, selectionType]);
    const derivedHalfSelectedKeySet = useMemo(() => (selectionType === 'radio' ? new Set() : new Set(derivedHalfSelectedKeys)), [derivedHalfSelectedKeys, selectionType]);
    // Save last selected key to enable range selection
    const [lastSelectedKey, setLastSelectedKey] = useState(null);
    // Reset if rowSelection reset
    React.useEffect(() => {
        if (!rowSelection) {
            setMergedSelectedKeys(EMPTY_LIST);
        }
    }, [!!rowSelection]);
    const setSelectedKeys = useCallback((keys, method) => {
        let availableKeys;
        let records;
        updatePreserveRecordsCache(keys);
        if (preserveSelectedRowKeys) {
            availableKeys = keys;
            records = keys.map((key) => preserveRecordsRef.current.get(key));
        }
        else {
            // Filter key which not exist in the `dataSource`
            availableKeys = [];
            records = [];
            keys.forEach((key) => {
                const record = getRecordByKey(key);
                if (record !== undefined) {
                    availableKeys.push(key);
                    records.push(record);
                }
            });
        }
        setMergedSelectedKeys(availableKeys);
        onSelectionChange === null || onSelectionChange === void 0 ? void 0 : onSelectionChange(availableKeys, records, { type: method });
    }, [setMergedSelectedKeys, getRecordByKey, onSelectionChange, preserveSelectedRowKeys]);
    // ====================== Selections ======================
    // Trigger single `onSelect` event
    const triggerSingleSelection = useCallback((key, selected, keys, event) => {
        if (onSelect) {
            const rows = keys.map((k) => getRecordByKey(k));
            onSelect(getRecordByKey(key), selected, rows, event);
        }
        setSelectedKeys(keys, 'single');
    }, [onSelect, getRecordByKey, setSelectedKeys]);
    const mergedSelections = useMemo(() => {
        if (!selections || hideSelectAll) {
            return null;
        }
        const selectionList = selections === true ? [SELECTION_ALL, SELECTION_INVERT, SELECTION_NONE] : selections;
        return selectionList
            .map((selection) => {
            if (selection === SELECTION_ALL) {
                return {
                    key: 'all',
                    text: tableLocale.selectionAll,
                    onSelect() {
                        setSelectedKeys(data
                            .map((record, index) => getRowKey(record, index))
                            .filter((key) => {
                            const checkProps = checkboxPropsMap.get(key);
                            return !(checkProps === null || checkProps === void 0 ? void 0 : checkProps.disabled) || derivedSelectedKeySet.has(key);
                        }), 'all');
                    },
                };
            }
            if (selection === SELECTION_INVERT) {
                return {
                    key: 'invert',
                    text: tableLocale.selectInvert,
                    onSelect() {
                        const keySet = new Set(derivedSelectedKeySet);
                        pageData.forEach((record, index) => {
                            const key = getRowKey(record, index);
                            const checkProps = checkboxPropsMap.get(key);
                            if (!(checkProps === null || checkProps === void 0 ? void 0 : checkProps.disabled)) {
                                if (keySet.has(key)) {
                                    keySet.delete(key);
                                }
                                else {
                                    keySet.add(key);
                                }
                            }
                        });
                        const keys = Array.from(keySet);
                        if (onSelectInvert) {
                            warning(false, 'Table', '`onSelectInvert` will be removed in future. Please use `onChange` instead.');
                            onSelectInvert(keys);
                        }
                        setSelectedKeys(keys, 'invert');
                    },
                };
            }
            if (selection === SELECTION_NONE) {
                return {
                    key: 'none',
                    text: tableLocale.selectNone,
                    onSelect() {
                        onSelectNone === null || onSelectNone === void 0 ? void 0 : onSelectNone();
                        setSelectedKeys(Array.from(derivedSelectedKeySet).filter((key) => {
                            const checkProps = checkboxPropsMap.get(key);
                            return checkProps === null || checkProps === void 0 ? void 0 : checkProps.disabled;
                        }), 'none');
                    },
                };
            }
            return selection;
        })
            .map((selection) => (Object.assign(Object.assign({}, selection), { onSelect: (...rest) => {
                var _a;
                (_a = selection.onSelect) === null || _a === void 0 ? void 0 : _a.call(selection, ...rest);
                setLastSelectedKey(null);
            } })));
    }, [selections, derivedSelectedKeySet, pageData, getRowKey, onSelectInvert, setSelectedKeys]);
    // ======================= Columns ========================
    const transformColumns = useCallback((columns) => {
        var _a;
        // >>>>>>>>>>> Skip if not exists `rowSelection`
        if (!rowSelection) {
            warning(!columns.includes(SELECTION_COLUMN), 'Table', '`rowSelection` is not config but `SELECTION_COLUMN` exists in the `columns`.');
            return columns.filter((col) => col !== SELECTION_COLUMN);
        }
        // >>>>>>>>>>> Support selection
        let cloneColumns = [...columns];
        const keySet = new Set(derivedSelectedKeySet);
        // Record key only need check with enabled
        const recordKeys = flattedData
            .map(getRowKey)
            .filter((key) => !checkboxPropsMap.get(key).disabled);
        const checkedCurrentAll = recordKeys.every((key) => keySet.has(key));
        const checkedCurrentSome = recordKeys.some((key) => keySet.has(key));
        const onSelectAllChange = () => {
            const changeKeys = [];
            if (checkedCurrentAll) {
                recordKeys.forEach((key) => {
                    keySet.delete(key);
                    changeKeys.push(key);
                });
            }
            else {
                recordKeys.forEach((key) => {
                    if (!keySet.has(key)) {
                        keySet.add(key);
                        changeKeys.push(key);
                    }
                });
            }
            const keys = Array.from(keySet);
            onSelectAll === null || onSelectAll === void 0 ? void 0 : onSelectAll(!checkedCurrentAll, keys.map((k) => getRecordByKey(k)), changeKeys.map((k) => getRecordByKey(k)));
            setSelectedKeys(keys, 'all');
            setLastSelectedKey(null);
        };
        // ===================== Render =====================
        // Title Cell
        let title;
        if (selectionType !== 'radio') {
            let customizeSelections;
            if (mergedSelections) {
                const menu = {
                    getPopupContainer,
                    items: mergedSelections.map((selection, index) => {
                        const { key, text, onSelect: onSelectionClick } = selection;
                        return {
                            key: key !== null && key !== void 0 ? key : index,
                            onClick: () => {
                                onSelectionClick === null || onSelectionClick === void 0 ? void 0 : onSelectionClick(recordKeys);
                            },
                            label: text,
                        };
                    }),
                };
                customizeSelections = (React.createElement("div", { className: `${prefixCls}-selection-extra` },
                    React.createElement(Dropdown, { menu: menu, getPopupContainer: getPopupContainer },
                        React.createElement("span", null,
                            React.createElement(DownOutlined, null)))));
            }
            const allDisabledData = flattedData
                .map((record, index) => {
                const key = getRowKey(record, index);
                const checkboxProps = checkboxPropsMap.get(key) || {};
                return Object.assign({ checked: keySet.has(key) }, checkboxProps);
            })
                .filter(({ disabled }) => disabled);
            const allDisabled = !!allDisabledData.length && allDisabledData.length === flattedData.length;
            const allDisabledAndChecked = allDisabled && allDisabledData.every(({ checked }) => checked);
            const allDisabledSomeChecked = allDisabled && allDisabledData.some(({ checked }) => checked);
            title = !hideSelectAll && (React.createElement("div", { className: `${prefixCls}-selection` },
                React.createElement(Checkbox, { checked: !allDisabled ? !!flattedData.length && checkedCurrentAll : allDisabledAndChecked, indeterminate: !allDisabled
                        ? !checkedCurrentAll && checkedCurrentSome
                        : !allDisabledAndChecked && allDisabledSomeChecked, onChange: onSelectAllChange, disabled: flattedData.length === 0 || allDisabled, "aria-label": customizeSelections ? 'Custom selection' : 'Select all', skipGroup: true }),
                customizeSelections));
        }
        // Body Cell
        let renderCell;
        if (selectionType === 'radio') {
            renderCell = (_, record, index) => {
                const key = getRowKey(record, index);
                const checked = keySet.has(key);
                return {
                    node: (React.createElement(Radio, Object.assign({}, checkboxPropsMap.get(key), { checked: checked, onClick: (e) => e.stopPropagation(), onChange: (event) => {
                            if (!keySet.has(key)) {
                                triggerSingleSelection(key, true, [key], event.nativeEvent);
                            }
                        } }))),
                    checked,
                };
            };
        }
        else {
            renderCell = (_, record, index) => {
                var _a;
                const key = getRowKey(record, index);
                const checked = keySet.has(key);
                const indeterminate = derivedHalfSelectedKeySet.has(key);
                const checkboxProps = checkboxPropsMap.get(key);
                let mergedIndeterminate;
                if (expandType === 'nest') {
                    mergedIndeterminate = indeterminate;
                    warning(typeof (checkboxProps === null || checkboxProps === void 0 ? void 0 : checkboxProps.indeterminate) !== 'boolean', 'Table', 'set `indeterminate` using `rowSelection.getCheckboxProps` is not allowed with tree structured dataSource.');
                }
                else {
                    mergedIndeterminate = (_a = checkboxProps === null || checkboxProps === void 0 ? void 0 : checkboxProps.indeterminate) !== null && _a !== void 0 ? _a : indeterminate;
                }
                // Record checked
                return {
                    node: (React.createElement(Checkbox, Object.assign({}, checkboxProps, { indeterminate: mergedIndeterminate, checked: checked, skipGroup: true, onClick: (e) => e.stopPropagation(), onChange: ({ nativeEvent }) => {
                            const { shiftKey } = nativeEvent;
                            let startIndex = -1;
                            let endIndex = -1;
                            // Get range of this
                            if (shiftKey && checkStrictly) {
                                const pointKeys = new Set([lastSelectedKey, key]);
                                recordKeys.some((recordKey, recordIndex) => {
                                    if (pointKeys.has(recordKey)) {
                                        if (startIndex === -1) {
                                            startIndex = recordIndex;
                                        }
                                        else {
                                            endIndex = recordIndex;
                                            return true;
                                        }
                                    }
                                    return false;
                                });
                            }
                            if (endIndex !== -1 && startIndex !== endIndex && checkStrictly) {
                                // Batch update selections
                                const rangeKeys = recordKeys.slice(startIndex, endIndex + 1);
                                const changedKeys = [];
                                if (checked) {
                                    rangeKeys.forEach((recordKey) => {
                                        if (keySet.has(recordKey)) {
                                            changedKeys.push(recordKey);
                                            keySet.delete(recordKey);
                                        }
                                    });
                                }
                                else {
                                    rangeKeys.forEach((recordKey) => {
                                        if (!keySet.has(recordKey)) {
                                            changedKeys.push(recordKey);
                                            keySet.add(recordKey);
                                        }
                                    });
                                }
                                const keys = Array.from(keySet);
                                onSelectMultiple === null || onSelectMultiple === void 0 ? void 0 : onSelectMultiple(!checked, keys.map((recordKey) => getRecordByKey(recordKey)), changedKeys.map((recordKey) => getRecordByKey(recordKey)));
                                setSelectedKeys(keys, 'multiple');
                            }
                            else {
                                // Single record selected
                                const originCheckedKeys = derivedSelectedKeys;
                                if (checkStrictly) {
                                    const checkedKeys = checked
                                        ? arrDel(originCheckedKeys, key)
                                        : arrAdd(originCheckedKeys, key);
                                    triggerSingleSelection(key, !checked, checkedKeys, nativeEvent);
                                }
                                else {
                                    // Always fill first
                                    const result = conductCheck([...originCheckedKeys, key], true, keyEntities, isCheckboxDisabled);
                                    const { checkedKeys, halfCheckedKeys } = result;
                                    let nextCheckedKeys = checkedKeys;
                                    // If remove, we do it again to correction
                                    if (checked) {
                                        const tempKeySet = new Set(checkedKeys);
                                        tempKeySet.delete(key);
                                        nextCheckedKeys = conductCheck(Array.from(tempKeySet), { checked: false, halfCheckedKeys }, keyEntities, isCheckboxDisabled).checkedKeys;
                                    }
                                    triggerSingleSelection(key, !checked, nextCheckedKeys, nativeEvent);
                                }
                            }
                            if (checked) {
                                setLastSelectedKey(null);
                            }
                            else {
                                setLastSelectedKey(key);
                            }
                        } }))),
                    checked,
                };
            };
        }
        const renderSelectionCell = (_, record, index) => {
            const { node, checked } = renderCell(_, record, index);
            if (customizeRenderCell) {
                return customizeRenderCell(checked, record, index, node);
            }
            return node;
        };
        // Insert selection column if not exist
        if (!cloneColumns.includes(SELECTION_COLUMN)) {
            // Always after expand icon
            if (cloneColumns.findIndex((col) => { var _a; return ((_a = col[INTERNAL_COL_DEFINE]) === null || _a === void 0 ? void 0 : _a.columnType) === 'EXPAND_COLUMN'; }) === 0) {
                const [expandColumn, ...restColumns] = cloneColumns;
                cloneColumns = [expandColumn, SELECTION_COLUMN, ...restColumns];
            }
            else {
                // Normal insert at first column
                cloneColumns = [SELECTION_COLUMN, ...cloneColumns];
            }
        }
        // Deduplicate selection column
        const selectionColumnIndex = cloneColumns.indexOf(SELECTION_COLUMN);
        warning(cloneColumns.filter((col) => col === SELECTION_COLUMN).length <= 1, 'Table', 'Multiple `SELECTION_COLUMN` exist in `columns`.');
        cloneColumns = cloneColumns.filter((column, index) => column !== SELECTION_COLUMN || index === selectionColumnIndex);
        // Fixed column logic
        const prevCol = cloneColumns[selectionColumnIndex - 1];
        const nextCol = cloneColumns[selectionColumnIndex + 1];
        let mergedFixed = fixed;
        if (mergedFixed === undefined) {
            if ((nextCol === null || nextCol === void 0 ? void 0 : nextCol.fixed) !== undefined) {
                mergedFixed = nextCol.fixed;
            }
            else if ((prevCol === null || prevCol === void 0 ? void 0 : prevCol.fixed) !== undefined) {
                mergedFixed = prevCol.fixed;
            }
        }
        if (mergedFixed &&
            prevCol &&
            ((_a = prevCol[INTERNAL_COL_DEFINE]) === null || _a === void 0 ? void 0 : _a.columnType) === 'EXPAND_COLUMN' &&
            prevCol.fixed === undefined) {
            prevCol.fixed = mergedFixed;
        }
        const columnCls = classNames(`${prefixCls}-selection-col`, {
            [`${prefixCls}-selection-col-with-dropdown`]: selections && selectionType === 'checkbox',
        });
        // Replace with real selection column
        const selectionColumn = {
            fixed: mergedFixed,
            width: selectionColWidth,
            className: `${prefixCls}-selection-column`,
            title: rowSelection.columnTitle || title,
            render: renderSelectionCell,
            onCell: rowSelection.onCell,
            [INTERNAL_COL_DEFINE]: { className: columnCls },
        };
        return cloneColumns.map((col) => (col === SELECTION_COLUMN ? selectionColumn : col));
    }, [
        getRowKey,
        flattedData,
        rowSelection,
        derivedSelectedKeys,
        derivedSelectedKeySet,
        derivedHalfSelectedKeySet,
        selectionColWidth,
        mergedSelections,
        expandType,
        lastSelectedKey,
        checkboxPropsMap,
        onSelectMultiple,
        triggerSingleSelection,
        isCheckboxDisabled,
    ]);
    return [transformColumns, derivedSelectedKeySet];
};
export default useSelection;
