import React from 'react';
import { generateColor } from '../util';
const ColorClear = ({ prefixCls, value, onChange }) => {
    const handleClick = () => {
        if (value) {
            const hsba = value.toHsb();
            hsba.a = 0;
            const genColor = generateColor(hsba);
            onChange === null || onChange === void 0 ? void 0 : onChange(genColor);
        }
    };
    return React.createElement("div", { className: `${prefixCls}-clear`, onClick: handleClick });
};
export default ColorClear;
