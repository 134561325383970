import { Color as RcColor } from '@rc-component/color-picker';
import { getHex } from './util';
export class ColorFactory {
    constructor(color) {
        this.metaColor = new RcColor(color);
    }
    toHsb() {
        return this.metaColor.toHsb();
    }
    toHsbString() {
        return this.metaColor.toHsbString();
    }
    toHex() {
        return getHex(this.toHexString(), this.metaColor.getAlpha() < 1);
    }
    toHexString() {
        return this.metaColor.getAlpha() === 1
            ? this.metaColor.toHexString()
            : this.metaColor.toHex8String();
    }
    toRgb() {
        return this.metaColor.toRgb();
    }
    toRgbString() {
        return this.metaColor.toRgbString();
    }
}
