import { ColorBlock } from '@rc-component/color-picker';
import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import React, { useMemo } from 'react';
import Collapse from '../../collapse';
import { useLocale } from '../../locale';
import { generateColor } from '../util';
const { Panel } = Collapse;
const genPresetColor = (list) => list.map((value) => {
    value.colors = value.colors.map(generateColor);
    return value;
});
const isBright = (value) => {
    const { r, g, b, a } = value.toRgb();
    if (a <= 0.5) {
        return true;
    }
    return r * 0.299 + g * 0.587 + b * 0.114 > 192;
};
const ColorPresets = ({ prefixCls, presets, value: color, onChange }) => {
    const [locale] = useLocale('ColorPicker');
    const [presetsValue] = useMergedState(genPresetColor(presets), {
        value: genPresetColor(presets),
        postState: genPresetColor,
    });
    const colorPresetsPrefixCls = `${prefixCls}-presets`;
    const activeKeys = useMemo(() => presetsValue.map((preset) => `panel-${preset.label}`), [presetsValue]);
    const handleClick = (colorValue) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(colorValue);
    };
    return (React.createElement("div", { className: colorPresetsPrefixCls },
        React.createElement(Collapse, { defaultActiveKey: activeKeys, ghost: true }, presetsValue.map((preset) => {
            var _a;
            return (React.createElement(Panel, { header: React.createElement("div", { className: `${colorPresetsPrefixCls}-label` }, preset === null || preset === void 0 ? void 0 : preset.label), key: `panel-${preset === null || preset === void 0 ? void 0 : preset.label}` },
                React.createElement("div", { className: `${colorPresetsPrefixCls}-items` }, Array.isArray(preset === null || preset === void 0 ? void 0 : preset.colors) && ((_a = preset.colors) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (preset.colors.map((presetColor) => (React.createElement(ColorBlock, { key: `preset-${presetColor.toHexString()}`, color: generateColor(presetColor).toRgbString(), prefixCls: prefixCls, className: classNames(`${colorPresetsPrefixCls}-color`, {
                        [`${colorPresetsPrefixCls}-color-checked`]: presetColor.toHexString() === (color === null || color === void 0 ? void 0 : color.toHexString()),
                        [`${colorPresetsPrefixCls}-color-bright`]: isBright(presetColor),
                    }), onClick: () => handleClick(presetColor) })))) : (React.createElement("span", { className: `${colorPresetsPrefixCls}-empty` }, locale.presetEmpty)))));
        }))));
};
export default ColorPresets;
