var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import RcMenu from 'rc-menu';
import * as React from 'react';
import { forwardRef } from 'react';
import omit from 'rc-util/lib/omit';
import useEvent from 'rc-util/lib/hooks/useEvent';
import classNames from 'classnames';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import warning from '../_util/warning';
import initCollapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import { ConfigContext } from '../config-provider';
import useStyle from './style';
import OverrideContext from './OverrideContext';
import useItems from './hooks/useItems';
import MenuContext from './MenuContext';
const InternalMenu = forwardRef((props, ref) => {
    var _a, _b;
    const override = React.useContext(OverrideContext);
    const overrideObj = override || {};
    const { getPrefixCls, getPopupContainer, direction } = React.useContext(ConfigContext);
    const rootPrefixCls = getPrefixCls();
    const { prefixCls: customizePrefixCls, className, theme = 'light', expandIcon, _internalDisableMenuItemTitleTooltip, inlineCollapsed, siderCollapsed, items, children, rootClassName, mode, selectable, onClick } = props, restProps = __rest(props, ["prefixCls", "className", "theme", "expandIcon", "_internalDisableMenuItemTitleTooltip", "inlineCollapsed", "siderCollapsed", "items", "children", "rootClassName", "mode", "selectable", "onClick"]);
    const passedProps = omit(restProps, ['collapsedWidth']);
    // ========================= Items ===========================
    const mergedChildren = useItems(items) || children;
    // ======================== Warning ==========================
    warning(!('inlineCollapsed' in props && mode !== 'inline'), 'Menu', '`inlineCollapsed` should only be used when `mode` is inline.');
    warning(!(props.siderCollapsed !== undefined && 'inlineCollapsed' in props), 'Menu', '`inlineCollapsed` not control Menu under Sider. Should set `collapsed` on Sider instead.');
    warning('items' in props && !children, 'Menu', '`children` will be removed in next major version. Please use `items` instead.');
    (_a = overrideObj.validator) === null || _a === void 0 ? void 0 : _a.call(overrideObj, { mode });
    // ========================== Click ==========================
    // Tell dropdown that item clicked
    const onItemClick = useEvent((...args) => {
        var _a;
        onClick === null || onClick === void 0 ? void 0 : onClick(...args);
        (_a = overrideObj.onClick) === null || _a === void 0 ? void 0 : _a.call(overrideObj);
    });
    // ========================== Mode ===========================
    const mergedMode = overrideObj.mode || mode;
    // ======================= Selectable ========================
    const mergedSelectable = selectable !== null && selectable !== void 0 ? selectable : overrideObj.selectable;
    // ======================== Collapsed ========================
    // Inline Collapsed
    const mergedInlineCollapsed = React.useMemo(() => {
        if (siderCollapsed !== undefined) {
            return siderCollapsed;
        }
        return inlineCollapsed;
    }, [inlineCollapsed, siderCollapsed]);
    const defaultMotions = {
        horizontal: { motionName: `${rootPrefixCls}-slide-up` },
        inline: initCollapseMotion(rootPrefixCls),
        other: { motionName: `${rootPrefixCls}-zoom-big` },
    };
    const prefixCls = getPrefixCls('menu', customizePrefixCls || overrideObj.prefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls, !override);
    const menuClassName = classNames(`${prefixCls}-${theme}`, className);
    // ====================== Expand Icon ========================
    let mergedExpandIcon;
    if (typeof expandIcon === 'function') {
        mergedExpandIcon = expandIcon;
    }
    else {
        const beClone = expandIcon || overrideObj.expandIcon;
        mergedExpandIcon = cloneElement(beClone, {
            className: classNames(`${prefixCls}-submenu-expand-icon`, (_b = beClone === null || beClone === void 0 ? void 0 : beClone.props) === null || _b === void 0 ? void 0 : _b.className),
        });
    }
    // ======================== Context ==========================
    const contextValue = React.useMemo(() => ({
        prefixCls,
        inlineCollapsed: mergedInlineCollapsed || false,
        direction,
        firstLevel: true,
        theme,
        mode: mergedMode,
        disableMenuItemTitleTooltip: _internalDisableMenuItemTitleTooltip,
    }), [prefixCls, mergedInlineCollapsed, direction, _internalDisableMenuItemTitleTooltip, theme]);
    // ========================= Render ==========================
    return wrapSSR(React.createElement(OverrideContext.Provider, { value: null },
        React.createElement(MenuContext.Provider, { value: contextValue },
            React.createElement(RcMenu, Object.assign({ getPopupContainer: getPopupContainer, overflowedIndicator: React.createElement(EllipsisOutlined, null), overflowedIndicatorPopupClassName: classNames(prefixCls, `${prefixCls}-${theme}`), mode: mergedMode, selectable: mergedSelectable, onClick: onItemClick }, passedProps, { inlineCollapsed: mergedInlineCollapsed, className: menuClassName, prefixCls: prefixCls, direction: direction, defaultMotions: defaultMotions, expandIcon: mergedExpandIcon, ref: ref, rootClassName: classNames(rootClassName, hashId) }), mergedChildren))));
});
export default InternalMenu;
