var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TinyColor } from '@ctrl/tinycolor';
import seedToken from '../themes/seed';
import getAlphaColor from './getAlphaColor';
/**
 * Seed (designer) > Derivative (designer) > Alias (developer).
 *
 * Merge seed & derivative & override token and generate alias token for developer.
 */
export default function formatToken(derivativeToken) {
    const { override } = derivativeToken, restToken = __rest(derivativeToken, ["override"]);
    const overrideTokens = Object.assign({}, override);
    Object.keys(seedToken).forEach((token) => {
        delete overrideTokens[token];
    });
    const mergedToken = Object.assign(Object.assign({}, restToken), overrideTokens);
    const screenXS = 480;
    const screenSM = 576;
    const screenMD = 768;
    const screenLG = 992;
    const screenXL = 1200;
    const screenXXL = 1600;
    // Motion
    if (mergedToken.motion === false) {
        const fastDuration = '0s';
        mergedToken.motionDurationFast = fastDuration;
        mergedToken.motionDurationMid = fastDuration;
        mergedToken.motionDurationSlow = fastDuration;
    }
    // Generate alias token
    const aliasToken = Object.assign(Object.assign(Object.assign({}, mergedToken), { colorLink: mergedToken.colorInfoText, colorLinkHover: mergedToken.colorInfoHover, colorLinkActive: mergedToken.colorInfoActive, 
        // ============== Background ============== //
        colorFillContent: mergedToken.colorFillSecondary, colorFillContentHover: mergedToken.colorFill, colorFillAlter: mergedToken.colorFillQuaternary, colorBgContainerDisabled: mergedToken.colorFillTertiary, 
        // ============== Split ============== //
        colorBorderBg: mergedToken.colorBgContainer, colorSplit: getAlphaColor(mergedToken.colorBorderSecondary, mergedToken.colorBgContainer), 
        // ============== Text ============== //
        colorTextPlaceholder: mergedToken.colorTextQuaternary, colorTextDisabled: mergedToken.colorTextQuaternary, colorTextHeading: mergedToken.colorText, colorTextLabel: mergedToken.colorTextSecondary, colorTextDescription: mergedToken.colorTextTertiary, colorTextLightSolid: mergedToken.colorWhite, colorHighlight: mergedToken.colorError, colorBgTextHover: mergedToken.colorFillSecondary, colorBgTextActive: mergedToken.colorFill, colorIcon: mergedToken.colorTextTertiary, colorIconHover: mergedToken.colorText, colorErrorOutline: getAlphaColor(mergedToken.colorErrorBg, mergedToken.colorBgContainer), colorWarningOutline: getAlphaColor(mergedToken.colorWarningBg, mergedToken.colorBgContainer), 
        // Font
        fontSizeIcon: mergedToken.fontSizeSM, 
        // Line
        lineWidthFocus: mergedToken.lineWidth * 4, 
        // Control
        lineWidth: mergedToken.lineWidth, controlOutlineWidth: mergedToken.lineWidth * 2, 
        // Checkbox size and expand icon size
        controlInteractiveSize: mergedToken.controlHeight / 2, controlItemBgHover: mergedToken.colorFillTertiary, controlItemBgActive: mergedToken.colorPrimaryBg, controlItemBgActiveHover: mergedToken.colorPrimaryBgHover, controlItemBgActiveDisabled: mergedToken.colorFill, controlTmpOutline: mergedToken.colorFillQuaternary, controlOutline: getAlphaColor(mergedToken.colorPrimaryBg, mergedToken.colorBgContainer), lineType: mergedToken.lineType, borderRadius: mergedToken.borderRadius, borderRadiusXS: mergedToken.borderRadiusXS, borderRadiusSM: mergedToken.borderRadiusSM, borderRadiusLG: mergedToken.borderRadiusLG, fontWeightStrong: 600, opacityLoading: 0.65, linkDecoration: 'none', linkHoverDecoration: 'none', linkFocusDecoration: 'none', controlPaddingHorizontal: 12, controlPaddingHorizontalSM: 8, paddingXXS: mergedToken.sizeXXS, paddingXS: mergedToken.sizeXS, paddingSM: mergedToken.sizeSM, padding: mergedToken.size, paddingMD: mergedToken.sizeMD, paddingLG: mergedToken.sizeLG, paddingXL: mergedToken.sizeXL, paddingContentHorizontalLG: mergedToken.sizeLG, paddingContentVerticalLG: mergedToken.sizeMS, paddingContentHorizontal: mergedToken.sizeMS, paddingContentVertical: mergedToken.sizeSM, paddingContentHorizontalSM: mergedToken.size, paddingContentVerticalSM: mergedToken.sizeXS, marginXXS: mergedToken.sizeXXS, marginXS: mergedToken.sizeXS, marginSM: mergedToken.sizeSM, margin: mergedToken.size, marginMD: mergedToken.sizeMD, marginLG: mergedToken.sizeLG, marginXL: mergedToken.sizeXL, marginXXL: mergedToken.sizeXXL, boxShadow: `
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05)
    `, boxShadowSecondary: `
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05)
    `, boxShadowTertiary: `
      0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02),
      0 2px 4px 0 rgba(0, 0, 0, 0.02)
    `, screenXS, screenXSMin: screenXS, screenXSMax: screenSM - 1, screenSM, screenSMMin: screenSM, screenSMMax: screenMD - 1, screenMD, screenMDMin: screenMD, screenMDMax: screenLG - 1, screenLG, screenLGMin: screenLG, screenLGMax: screenXL - 1, screenXL, screenXLMin: screenXL, screenXLMax: screenXXL - 1, screenXXL, screenXXLMin: screenXXL, boxShadowPopoverArrow: '2px 2px 5px rgba(0, 0, 0, 0.05)', boxShadowCard: `
      0 1px 2px -2px ${new TinyColor('rgba(0, 0, 0, 0.16)').toRgbString()},
      0 3px 6px 0 ${new TinyColor('rgba(0, 0, 0, 0.12)').toRgbString()},
      0 5px 12px 4px ${new TinyColor('rgba(0, 0, 0, 0.09)').toRgbString()}
    `, boxShadowDrawerRight: `
      -6px 0 16px 0 rgba(0, 0, 0, 0.08),
      -3px 0 6px -4px rgba(0, 0, 0, 0.12),
      -9px 0 28px 8px rgba(0, 0, 0, 0.05)
    `, boxShadowDrawerLeft: `
      6px 0 16px 0 rgba(0, 0, 0, 0.08),
      3px 0 6px -4px rgba(0, 0, 0, 0.12),
      9px 0 28px 8px rgba(0, 0, 0, 0.05)
    `, boxShadowDrawerUp: `
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05)
    `, boxShadowDrawerDown: `
      0 -6px 16px 0 rgba(0, 0, 0, 0.08),
      0 -3px 6px -4px rgba(0, 0, 0, 0.12),
      0 -9px 28px 8px rgba(0, 0, 0, 0.05)
    `, boxShadowTabsOverflowLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.08)', boxShadowTabsOverflowRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)', boxShadowTabsOverflowTop: 'inset 0 10px 8px -8px rgba(0, 0, 0, 0.08)', boxShadowTabsOverflowBottom: 'inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08)' }), overrideTokens);
    return aliasToken;
}
