var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createTheme } from '@ant-design/cssinjs';
import IconContext from '@ant-design/icons/lib/components/Context';
import { FormProvider as RcFormProvider } from 'rc-field-form';
import { setValues } from 'rc-field-form/lib/utils/valueUtil';
import useMemo from 'rc-util/lib/hooks/useMemo';
import * as React from 'react';
import warning from '../_util/warning';
import LocaleProvider, { ANT_MARK } from '../locale';
import LocaleContext from '../locale/context';
import defaultLocale from '../locale/en_US';
import { DesignTokenContext } from '../theme/internal';
import defaultSeedToken from '../theme/themes/seed';
import { ConfigConsumer, ConfigContext, defaultIconPrefixCls } from './context';
import { registerTheme } from './cssVariables';
import { DisabledContextProvider } from './DisabledContext';
import useConfig from './hooks/useConfig';
import useTheme from './hooks/useTheme';
import MotionWrapper from './MotionWrapper';
import SizeContext, { SizeContextProvider } from './SizeContext';
import useStyle from './style';
/**
 * Since too many feedback using static method like `Modal.confirm` not getting theme,
 * we record the theme register info here to help developer get warning info.
 */
let existThemeConfig = false;
export const warnContext = process.env.NODE_ENV !== 'production'
    ? (componentName) => {
        warning(!existThemeConfig, componentName, `Static function can not consume context like dynamic theme. Please use 'App' component instead.`);
    }
    : /* istanbul ignore next */
        null;
export { ConfigContext, ConfigConsumer, };
export { defaultIconPrefixCls };
export const configConsumerProps = [
    'getTargetContainer',
    'getPopupContainer',
    'rootPrefixCls',
    'getPrefixCls',
    'renderEmpty',
    'csp',
    'autoInsertSpaceInButton',
    'locale',
    'pageHeader',
];
// These props is used by `useContext` directly in sub component
const PASSED_PROPS = [
    'getTargetContainer',
    'getPopupContainer',
    'renderEmpty',
    'pageHeader',
    'input',
    'pagination',
    'form',
    'select',
];
export const defaultPrefixCls = 'ant';
let globalPrefixCls;
let globalIconPrefixCls;
function getGlobalPrefixCls() {
    return globalPrefixCls || defaultPrefixCls;
}
function getGlobalIconPrefixCls() {
    return globalIconPrefixCls || defaultIconPrefixCls;
}
const setGlobalConfig = ({ prefixCls, iconPrefixCls, theme, }) => {
    if (prefixCls !== undefined) {
        globalPrefixCls = prefixCls;
    }
    if (iconPrefixCls !== undefined) {
        globalIconPrefixCls = iconPrefixCls;
    }
    if (theme) {
        registerTheme(getGlobalPrefixCls(), theme);
    }
};
export const globalConfig = () => ({
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return suffixCls ? `${getGlobalPrefixCls()}-${suffixCls}` : getGlobalPrefixCls();
    },
    getIconPrefixCls: getGlobalIconPrefixCls,
    getRootPrefixCls: () => {
        // If Global prefixCls provided, use this
        if (globalPrefixCls) {
            return globalPrefixCls;
        }
        // Fallback to default prefixCls
        return getGlobalPrefixCls();
    },
});
const ProviderChildren = (props) => {
    const { children, csp: customCsp, autoInsertSpaceInButton, form, locale, componentSize, direction, space, virtual, dropdownMatchSelectWidth, popupMatchSelectWidth, popupOverflow, legacyLocale, parentContext, iconPrefixCls: customIconPrefixCls, theme, componentDisabled, } = props;
    // =================================== Warning ===================================
    if (process.env.NODE_ENV !== 'production') {
        warning(dropdownMatchSelectWidth === undefined, 'ConfigProvider', '`dropdownMatchSelectWidth` is deprecated. Please use `popupMatchSelectWidth` instead.');
    }
    // =================================== Context ===================================
    const getPrefixCls = React.useCallback((suffixCls, customizePrefixCls) => {
        const { prefixCls } = props;
        if (customizePrefixCls)
            return customizePrefixCls;
        const mergedPrefixCls = prefixCls || parentContext.getPrefixCls('');
        return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
    }, [parentContext.getPrefixCls, props.prefixCls]);
    const iconPrefixCls = customIconPrefixCls || parentContext.iconPrefixCls || defaultIconPrefixCls;
    const shouldWrapSSR = iconPrefixCls !== parentContext.iconPrefixCls;
    const csp = customCsp || parentContext.csp;
    const wrapSSR = useStyle(iconPrefixCls, csp);
    const mergedTheme = useTheme(theme, parentContext.theme);
    if (process.env.NODE_ENV !== 'production') {
        existThemeConfig = existThemeConfig || !!mergedTheme;
    }
    const baseConfig = {
        csp,
        autoInsertSpaceInButton,
        locale: locale || legacyLocale,
        direction,
        space,
        virtual,
        popupMatchSelectWidth: popupMatchSelectWidth !== null && popupMatchSelectWidth !== void 0 ? popupMatchSelectWidth : dropdownMatchSelectWidth,
        popupOverflow,
        getPrefixCls,
        iconPrefixCls,
        theme: mergedTheme,
    };
    const config = Object.assign({}, parentContext);
    Object.keys(baseConfig).forEach((key) => {
        if (baseConfig[key] !== undefined) {
            config[key] = baseConfig[key];
        }
    });
    // Pass the props used by `useContext` directly with child component.
    // These props should merged into `config`.
    PASSED_PROPS.forEach((propName) => {
        const propValue = props[propName];
        if (propValue) {
            config[propName] = propValue;
        }
    });
    // https://github.com/ant-design/ant-design/issues/27617
    const memoedConfig = useMemo(() => config, config, (prevConfig, currentConfig) => {
        const prevKeys = Object.keys(prevConfig);
        const currentKeys = Object.keys(currentConfig);
        return (prevKeys.length !== currentKeys.length ||
            prevKeys.some((key) => prevConfig[key] !== currentConfig[key]));
    });
    const memoIconContextValue = React.useMemo(() => ({ prefixCls: iconPrefixCls, csp }), [iconPrefixCls, csp]);
    let childNode = shouldWrapSSR ? wrapSSR(children) : children;
    const validateMessages = React.useMemo(() => {
        var _a, _b, _c;
        return setValues({}, ((_a = defaultLocale.Form) === null || _a === void 0 ? void 0 : _a.defaultValidateMessages) || {}, ((_c = (_b = memoedConfig.locale) === null || _b === void 0 ? void 0 : _b.Form) === null || _c === void 0 ? void 0 : _c.defaultValidateMessages) || {}, (form === null || form === void 0 ? void 0 : form.validateMessages) || {});
    }, [memoedConfig, form === null || form === void 0 ? void 0 : form.validateMessages]);
    if (Object.keys(validateMessages).length > 0) {
        childNode = React.createElement(RcFormProvider, { validateMessages: validateMessages }, children);
    }
    if (locale) {
        childNode = (React.createElement(LocaleProvider, { locale: locale, _ANT_MARK__: ANT_MARK }, childNode));
    }
    if (iconPrefixCls || csp) {
        childNode = (React.createElement(IconContext.Provider, { value: memoIconContextValue }, childNode));
    }
    if (componentSize) {
        childNode = React.createElement(SizeContextProvider, { size: componentSize }, childNode);
    }
    // =================================== Motion ===================================
    childNode = React.createElement(MotionWrapper, null, childNode);
    // ================================ Dynamic theme ================================
    const memoTheme = React.useMemo(() => {
        const _a = mergedTheme || {}, { algorithm, token } = _a, rest = __rest(_a, ["algorithm", "token"]);
        const themeObj = algorithm && (!Array.isArray(algorithm) || algorithm.length > 0)
            ? createTheme(algorithm)
            : undefined;
        return Object.assign(Object.assign({}, rest), { theme: themeObj, token: Object.assign(Object.assign({}, defaultSeedToken), token) });
    }, [mergedTheme]);
    if (theme) {
        childNode = (React.createElement(DesignTokenContext.Provider, { value: memoTheme }, childNode));
    }
    // =================================== Render ===================================
    if (componentDisabled !== undefined) {
        childNode = (React.createElement(DisabledContextProvider, { disabled: componentDisabled }, childNode));
    }
    return React.createElement(ConfigContext.Provider, { value: memoedConfig }, childNode);
};
const ConfigProvider = (props) => {
    const context = React.useContext(ConfigContext);
    const antLocale = React.useContext(LocaleContext);
    return React.createElement(ProviderChildren, Object.assign({ parentContext: context, legacyLocale: antLocale }, props));
};
ConfigProvider.ConfigContext = ConfigContext;
ConfigProvider.SizeContext = SizeContext;
ConfigProvider.config = setGlobalConfig;
ConfigProvider.useConfig = useConfig;
Object.defineProperty(ConfigProvider, 'SizeContext', {
    get: () => {
        warning(false, 'ConfigProvider', 'ConfigProvider.SizeContext is deprecated. Please use `ConfigProvider.useConfig().componentSize` instead.');
        return SizeContext;
    },
});
if (process.env.NODE_ENV !== 'production') {
    ConfigProvider.displayName = 'ConfigProvider';
}
export default ConfigProvider;
