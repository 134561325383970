import { genComponentStyleHook } from '../../theme/internal';
// =============================== Base ===============================
const genBaseStyle = (token) => {
    const { componentCls, iconCls, zIndexPopup, colorText, colorWarning, marginXXS, marginXS, fontSize, fontWeightStrong, } = token;
    return {
        [componentCls]: {
            zIndex: zIndexPopup,
            color: colorText,
            [`${componentCls}-message`]: {
                marginBottom: marginXS,
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'start',
                [`> ${componentCls}-message-icon ${iconCls}`]: {
                    color: colorWarning,
                    fontSize,
                    lineHeight: 1,
                    marginInlineEnd: marginXS,
                },
                [`${componentCls}-title`]: {
                    fontWeight: fontWeightStrong,
                    '&:only-child': {
                        fontWeight: 'normal',
                    },
                },
                [`${componentCls}-description`]: {
                    marginTop: marginXXS,
                },
            },
            [`${componentCls}-buttons`]: {
                textAlign: 'end',
                button: {
                    marginInlineStart: marginXS,
                },
            },
        },
    };
};
// ============================== Export ==============================
export default genComponentStyleHook('Popconfirm', (token) => genBaseStyle(token), (token) => {
    const { zIndexPopupBase } = token;
    return {
        zIndexPopup: zIndexPopupBase + 60,
    };
});
