var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcSegmented from 'rc-segmented';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import useSize from '../config-provider/hooks/useSize';
import useStyle from './style';
function isSegmentedLabeledOptionWithIcon(option) {
    return typeof option === 'object' && !!(option === null || option === void 0 ? void 0 : option.icon);
}
const Segmented = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, className, rootClassName, block, options = [], size: customSize = 'middle' } = props, restProps = __rest(props, ["prefixCls", "className", "rootClassName", "block", "options", "size"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('segmented', customizePrefixCls);
    // Style
    const [wrapSSR, hashId] = useStyle(prefixCls);
    // ===================== Size =====================
    const mergedSize = useSize(customSize);
    // syntactic sugar to support `icon` for Segmented Item
    const extendedOptions = React.useMemo(() => options.map((option) => {
        if (isSegmentedLabeledOptionWithIcon(option)) {
            const { icon, label } = option, restOption = __rest(option, ["icon", "label"]);
            return Object.assign(Object.assign({}, restOption), { label: (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: `${prefixCls}-item-icon` }, icon),
                    label && React.createElement("span", null, label))) });
        }
        return option;
    }), [options, prefixCls]);
    return wrapSSR(React.createElement(RcSegmented, Object.assign({}, restProps, { className: classNames(className, rootClassName, {
            [`${prefixCls}-block`]: block,
            [`${prefixCls}-sm`]: mergedSize === 'small',
            [`${prefixCls}-lg`]: mergedSize === 'large',
        }, hashId), options: extendedOptions, ref: ref, prefixCls: prefixCls, direction: direction })));
});
if (process.env.NODE_ENV !== 'production') {
    Segmented.displayName = 'Segmented';
}
export default Segmented;
